<template>
  <el-form
      class="lb-m-0 lb_top"
      label-position="top"
      label-width="140px"
  >
    <el-form-item
        :label="$t('issues.note')"
        required
    >
      <el-input
          :autosize="{minRows: 2, maxRows: 10}"
          :class="checkinputRequired('note') && !form.note ? 'validate-error' : ''"
          @keyup.ctrl.enter.native="save"
          name="note"
          type="textarea"
          v-model="form.content"
      >
      </el-input>
    </el-form-item>
    <el-form-item class="m-0 ptx-20 d-flex justify-content-end">
      <el-button
          :loading="loading.save"
          @click="save"
          type="primary"
      >
        {{ $t('system.save') }}
      </el-button>
      <el-button @click="$emit('cancel')">
        {{ $t('system.cancel') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import axios from 'axios';

export default {
  name: 'noteForm',
  props: [
    'saveURL', 'id', 'projectId',
  ],
  data() {
    return {
      form: {
        content: '',
        projectId: this.projectId,
      },
      loading: {
        save: false,
      },
      backend_errors: {},
      inputRequiredEmpty: [],
    };
  },
  methods: {
    save() {
      this.loading.save = true;
      this.checkValidation().then(() => {
        this.form.issueId = this.id;
        axios.post(this.saveURL, this.form)
            .then((response) => {
              this.loading.save = false;
              this.$emit('saved', response);
            })
            .catch((error) => {
              this.$emit('error', error);
              this.loading.save = false;
              this.backend_errors = error.response.data.errors;
            });
      }).catch(() => {
        this.loading.save = false;
      });
    },
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-textarea__inner[name=note] {
  max-height: 200px;
}
</style>
